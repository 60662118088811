import React, { createContext, useContext, useState } from 'react';

// Create Context
const VideoContext = createContext();

// Provider Component
export const VideoProvider = ({ children }) => {
  const [currentVideo, setCurrentVideo] = useState(null); // Track the currently playing video

  const playVideo = (videoRef) => {
    if (currentVideo && currentVideo !== videoRef) {
      currentVideo.pause(); // Pause the currently playing video
    }
    setCurrentVideo(videoRef); // Set the new current video
  };

  return (
    <VideoContext.Provider value={{ playVideo }}>
      {children}
    </VideoContext.Provider>
  );
};

// Custom Hook for Context
export const useVideoContext = () => {
  return useContext(VideoContext);
};
