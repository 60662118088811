import React, { useRef, useState } from 'react';
import emailjs from 'emailjs-com';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInstagram, faLinkedin, faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const Contact = () => {
  const form = useRef();
  const [isFormVisible, setFormVisible] = useState(false);

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      'service_ueb1nax', // replace with your EmailJS service ID
      'template_4umkrfi', // replace with your EmailJS template ID
      form.current, // the form reference
      'gib4OSHfktoi6GosP' // replace with your EmailJS public key
    )
    .then((result) => {
      console.log('Message Sent:', result.text);
      alert('Your message has been sent successfully!');
      form.current.reset(); // reset the form after submission
    }, (error) => {
      console.log('Error:', error.text);
      alert('Oops! Something went wrong. Please try again.');
    });
  };

  return (
    <section id="contact" className="py-20">
      <div className="container mx-auto text-center">
        <h2 className="text-4xl font-bold mb-6">Contact</h2>
        <p className="text-lg mb-6">If you'd like to work together, get in touch!</p>
        <button
          onClick={() => setFormVisible(!isFormVisible)}
          className="px-6 py-3 bg-yellow-400 text-gray-900 font-bold rounded-lg hover:bg-yellow-500"
        >
          Contact Us
        </button>

        {isFormVisible && (
          <form ref={form} onSubmit={sendEmail} className="mt-6 max-w-md mx-auto bg-gray-100 p-6 rounded-lg shadow-md">
            <div className="mb-4">
              <label className="block text-left mb-2 font-bold">Name</label>
              <input
                type="text"
                name="name"
                className="w-full px-4 py-2 border rounded-md"
                placeholder="Your Name"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-left mb-2 font-bold">Email</label>
              <input
                type="email"
                name="email"
                className="w-full px-4 py-2 border rounded-md"
                placeholder="Your Email"
                required
              />
            </div>
            <div className="mb-4">
              <label className="block text-left mb-2 font-bold">Message</label>
              <textarea
                name="message"
                className="w-full px-4 py-2 border rounded-md"
                placeholder="Your Message"
                rows="5"  
                required
              />
            </div>
            <button
              type="submit"
              className="px-6 py-3 bg-yellow-400 text-gray-900 font-bold rounded-lg hover:bg-yellow-500"
            >
              Send
            </button>
          </form>
        )}

        {/* Social Media Links */}
        <div className="mt-6">
          <p className="text-lg mb-4">Connect with me:</p>
          <div className="flex justify-center space-x-6">
            <a href="https://www.instagram.com/suraj.mov " target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faInstagram} className="text-3xl hover:text-yellow-500" />
            </a>
            <a href="https://in.linkedin.com/company/digidit-in" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faLinkedin} className="text-3xl hover:text-blue-600" />
            </a>
            <a href="https://wa.me/918080604008?text=Hi%20i%20got%20your%20number%20from%20digidit.in" target="_blank" rel="noopener noreferrer">
              <FontAwesomeIcon icon={faWhatsapp} className="text-3xl hover:text-green-500" />
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
