import React from 'react';
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import About from './components/About';
import Portfolio from './components/Portfolio';
import Contact from './components/Contact';
import Footer from './components/Footer';
import WhyChoose from './components/ChoseDIGIDIT';
import Testimonials from './components/Testimonial';
import AOS from 'aos';
import 'aos/dist/aos.css';




const App = () => {
  
    return (
    
    <div className="min-h-screen bg-gradient-to-b from-yellow-50  to-pink-50">#
      <Navbar />
      <Hero />
      <Portfolio />
      <WhyChoose />
      <About />
      <Testimonials/>
      <Contact />
      <Footer />
      {/* set NODE_OPTIONS=--openssl-legacy-provider && npm start */}

    </div>
  );
};

export default App;
