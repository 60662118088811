import React from 'react';

const WhyChoose = () => {
  return (
    <section className="py-20 text-center">
      <div className="container mx-auto">
        <h2 className="text-3xl font-semibold text-black-700 mb-8">WHY CHOOSE DIGIDIT?</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Quality Assurance Card */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <h3 className="text-2xl font-bold text-black-600 mb-4">Quality Assurance</h3>
            <p className="text-gray-600">
              Without compromising on quality, we use high-quality licensed assets to ensure there are no copyright issues.
            </p>
          </div>
          {/* 24/7 Support Card */}
          <div className="bg-white p-6 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
            <h3 className="text-2xl font-bold text-black-600 mb-4">24/7 Support</h3>  
            <p className="text-gray-600">
              We are available at all times to answer your inquiries, provide project updates, and offer the highest level of client support.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WhyChoose;
