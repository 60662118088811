import React from "react";
import Slider from "react-slick";

const Testimonials = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 600,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
  };

  const testimonials = [
    {
      quote:
        "Suraj is an exceptional video editor, always incredibly responsive and accommodating even with last-minute requests, consistently delivering high-quality work exactly as envisioned. I am so grateful to have him producing our Gen She Podcast.",
      name: "Avni Barman",
      position: "Founder of Gen She",
      image: "/Avni_Barman.jpeg", // Ensure this path is correct
    },
    {
        quote:
          "Suraj is an exceptional video editor, always incredibly responsive and accommodating even with last-minute requests, consistently delivering high-quality work exactly as envisioned. I am so grateful to have him producing our Gen She Podcast.",
        name: "Avni Barman",
        position: "Founder of Gen She",
        image: "/Avni_Barman.jpeg", // Ensure this path is correct
      },
      {
        quote:
          "Suraj is an exceptional video editor, always incredibly responsive and accommodating even with last-minute requests, consistently delivering high-quality work exactly as envisioned. I am so grateful to have him producing our Gen She Podcast.",
        name: "Avni Barman",
        position: "Founder of Gen She",
        image: "/Avni_Barman.jpeg", // Ensure this path is correct
      },
  ];

  return (
    <section id="testimonials" className="py-10 w-full ">
      <div className="container mx-auto text-center px-4 max-w-4xl">
        <h2 className="text-3xl font-bold mb-6">Clients Feedback</h2>
        <Slider {...settings}>
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="p-6 bg-white rounded-lg shadow-lg flex flex-col items-center justify-between"
              style={{ minHeight: "300px" }} // Ensures consistent card height
            >
              <p className="text-lg italic mb-6">"{testimonial.quote}"</p>
              <div className="flex items-center space-x-4">
                <img
                  src={testimonial.image}
                  alt={testimonial.name}
                  className="w-16 h-16 rounded-full object-cover"
                  onError={(e) => (e.target.style.display = "none")} // Hides the image if it fails to load
                />
                <div class="mx-auto text-center">
                  <h4 className="font-bold">{testimonial.name}</h4>
                  <p className="text-sm text-gray-900">{testimonial.position}</p>
                </div>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </section>
  );
};

export default Testimonials;
