import React, { useState, useRef, useEffect } from "react";

const Portfolio = () => {
  const [activeVideo, setActiveVideo] = useState(null); // Track currently playing video
  const videoRefs = useRef([]); // Store references to all video elements

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          const video = videoRefs.current[index];
          if (video) {
            if (entry.isIntersecting) {
              // Video is in the viewport
              if (index === activeVideo) {
                video.play();
              }
            } else {
              // Video is out of the viewport
              video.pause();
            }
          }
        });
      },
      { threshold: 0.5 } // Adjust threshold as needed
    );

    // Observe each video
    videoRefs.current.forEach((video) => {
      if (video) observer.observe(video);
    });

    // Cleanup observer on unmount
    return () => {
      observer.disconnect();
    };
  }, [activeVideo]);

  const handleVideoPlay = (index) => {
    // Pause all other videos
    videoRefs.current.forEach((video, i) => {
      if (i !== index && video) {
        video.pause();
      }
    });

    // Set the active video
    setActiveVideo(index);

    // Enable audio for the current video
    if (videoRefs.current[index]) {
      videoRefs.current[index].muted = false;
    }
  };

  
  const projects = [
    { title: "Project 1", video: "/video1.mp4" },
    { title: "Project 2", video: "/video6.mp4" },
    { title: "Project 3", video: "/video4.mp4" },
    { title: "Project 4", video: "/video12.mp4" },
  ];

  const videoGridClasses =
    "grid grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6";
  const videoContainerClasses =
    "shadow-lg rounded-lg overflow-hidden bg-gray-900 transform transition duration-300 ease-in-out";

  const viralVideoClasses =
    "relative overflow-hidden rounded-lg shadow-lg transform transition duration-300 ease-in-out hover:scale-105";

  return (
    <section id="portfolio" className="px-4 md:px-10 lg:px-20 py-20">
      <div className="container mx-auto text-center">
        <h1 className="text-3xl md:text-4xl font-bold mb-8">Portfolio</h1>
          {/* Shorts Section */}
        <h2 className="text-2xl md:text-3xl font-bold mb-6">Shorts</h2>
        <div className="flex justify-center space-x-6 mb-8">
          {/* TikTok Icon */}
          <a href="#Portfolio" rel="noopener noreferrer">
            <i className="fab fa-tiktok text-3xl text-black hover:text-purple-600"></i>
          </a>
          {/* Instagram Reels Icon */}
          <a href="#Portfolio"  rel="noopener noreferrer">
            <i className="fab fa-instagram text-3xl text-black hover:text-pink-600"></i>
          </a>
          {/* YouTube Shorts Icon */}
          <a href="#Portfolio" rel="noopener noreferrer">
            <i className="fab fa-youtube text-3xl text-black hover:text-red-600"></i>
          </a>
        </div>


        {/* Projects Grid */}
        
        <div className={videoGridClasses}>
          {projects.map((project, index) => (
            <div key={index} className={videoContainerClasses}>
              <div className="relative">
                <video
                  ref={(el) => (videoRefs.current[index] = el)} // Reference each video
                  loop
                  controls
                  muted
                  onPlay={() => handleVideoPlay(index)} // Handle play event
                  className="w-full h-auto object-cover"
                >
                  <source src={project.video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          ))}
        </div>

        {/* Viral Videos Section */}
        <div className="mt-16 py-16">
          <h2 className="text-2xl md:text-3xl font-bold mb-6 py-2"><a href="#ViralVideos" rel="noopener noreferrer">
  <i className="fas fa-chart-line text-3xl text-black hover:text-green-600"></i>
</a> Videos That Go Viral!</h2>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
            {/* Video 1 */}
            <div className={viralVideoClasses}>
              <a
                href="https://youtube.com/shorts/L_4a8CcQHo0?si=1DTMHzKVOZtUAbW4"
                target="_blank"
                rel="noopener noreferrer"
                className="block"
              >
                <img
                  src="/5.5M.png"
                  alt="Video 1"
                  className="w-full h-full object-cover"
                />
              </a>
            </div>

            {/* Video 2 */}
            <div className={viralVideoClasses}>
              <a
                href="https://www.instagram.com/reel/CvaKtRQARr0/"
                target="_blank"
                rel="noopener noreferrer"
                className="block"
              >
                <img
                  src="/3.6.png"
                  alt="Video 2"
                  className="w-full h-full object-cover"
                />
              </a>
            </div>

            {/* Video 3 */}
            <div className={viralVideoClasses}>
              <a
                href="https://youtube.com/shorts/ZcboBHn-lNk?si=f3zthBR6R_TVTbWg"
                target="_blank"
                rel="noopener noreferrer"
                className="block"
              >
                <img
                  src="/10M.png"
                  alt="Video 3"
                  className="w-full h-full object-cover"
                />
              </a>
            </div>

            {/* Video 4 */}
            <div className={viralVideoClasses}>
              <a
                href="https://www.instagram.com/reel/CukHO7tAfTK/"
                target="_blank"
                rel="noopener noreferrer"
                className="block"
              >
                <img
                  src="/4.6.png"
                  alt="Video 4"
                  className="w-full h-full object-cover"
                />
              </a>
            </div>
          </div>
        </div>

        {/* Music Video Section */}

        
        <div className="mt-16 text-center">
          <h2 className="text-2xl md:text-3xl font-bold mb-6 py-10"><a href="#Music" rel="noopener noreferrer">
  <i className="fas fa-music text-3xl text-black hover:text-blue-600"></i>
</a> Music Video</h2>

<div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <iframe
              className="w-full rounded-lg shadow-lg"
              height="315"
              src="https://www.youtube.com/embed/1FJat0b9qp8?si=vg-_qaWH2Tc7t8sF"
              title="Podcast 1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
         
          </div>
          
        </div>

        {/* Podcast Section */}
        <div className="mt-16">
          <h2 className="text-2xl md:text-3xl font-bold mb-6"><a href="#Microphone" rel="noopener noreferrer">
  <i className="fas fa-microphone text-3xl text-black hover:text-purple-600"></i>
</a> Podcast</h2>
          <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
            <iframe
              className="w-full rounded-lg shadow-lg"
              height="315"
              src="https://www.youtube.com/embed/DJN53lqaLQk"
              title="Podcast 1"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
            <iframe
              className="w-full rounded-lg shadow-lg"
              height="315"
              src="https://www.youtube.com/embed/YHU7NVFrpI4"
              title="Podcast 2"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>


          {/*  */}

          
        </div>
      </div>
    </section>
  );
};

export default Portfolio;
