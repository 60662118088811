import React, { useRef, useState, useEffect } from "react";

const About = () => {
  const projects = [
    { title: "Project 1", video: "/video7.mp4" },
    { title: "Project 2", video: "/video2.mp4" },
    { title: "Project 3", video: "/video3.mp4" },
  ];

  const [activeVideo, setActiveVideo] = useState(null);
  const videoRefs = useRef([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry, index) => {
          const video = videoRefs.current[index];
          if (video) {
            if (entry.isIntersecting) {
              // Video is in the viewport, continue if it's the active video
              if (index === activeVideo) {
                video.play();
              }
            } else {
              // Video is out of the viewport
              video.pause();
            }
          }
        });
      },
      { threshold: 0.5 } // Adjust threshold to determine how much visibility is needed
    );

    // Attach observer to each video
    videoRefs.current.forEach((video) => {
      if (video) observer.observe(video);
    });

    // Cleanup on component unmount
    return () => {
      observer.disconnect();
    };
  }, [activeVideo]);

  const handlePlay = (index) => {
    // Pause all other videos
    videoRefs.current.forEach((video, i) => {
      if (video && i !== index) {
        video.pause();
      }
    });

    // Set the active video
    setActiveVideo(index);
  };

  return (
    <section id="about" className="py-32">
      <div className="container mx-auto text-center px-20">
        <h2 className="text-4xl font-bold mb-6">About Me</h2>
        <div className="bg-white p-7 rounded-lg shadow-lg hover:shadow-xl transition-shadow duration-300">
          <div className="flex flex-col lg:flex-row items-center">
            {/* Image and Name Section */}
            <div className="text-center lg:text-left">
              <img
                src="/suraj.jpg" // Replace with your actual image path
                alt="Your image"
                className="w-48 h-48 rounded-full border-4 border-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 p-2 mb-4"
              />
              <h3 className="text-2xl font-semibold text-gray-800">Suraj Badgujar</h3> {/* Your name */}
            </div>

            {/* About Description */}
            <p className="text-lg max-w-2xl mx-auto mt-6 lg:mt-0">
              I began this journey as a freelance video editor that I constantly refine through self-learning.
              I have edited 500+ videos working with content creators such as AI Ankit, Gen She Pod, Sushasan, Tigp,
              and many more in the last 2 years. Apart from editing videos for others, I also create my own content on
              social media to learn new things. Currently, I am working on establishing my own creative agency named
              Digidit.in. We specialize in digital marketing and video editing for creators and brands. Whether it’s reels,
              YouTube videos, or business promos, we ensure quality, care, and timely delivery.
            </p>
          </div>
        </div>

        <div className=" px-4 md:px-10 lg:px-20 py-32">
          {/* Shorts Section */}
          <h2 className="text-2xl md:text-3xl font-bold mb-6 mt-20">My Content</h2>

          {/* Projects Grid */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">

          {projects.map((project, index) => (
            <div key={index} className="shadow-lg rounded-lg overflow-hidden">
              <div className="relative">
              <video
  ref={(el) => (videoRefs.current[index] = el)} // Reference each video
  loop
  controls
  onPlay={() => handlePlay(index)} // Handle play event
  className="w-full h-auto object-cover"
>
  <source src={project.video} type="video/mp4" />
  Your browser does not support the video tag.
</video>

              </div>
            </div>
          ))}

        </div>
        </div>
      </div>
    </section>
  );
};

export default About;
